/* autoprefixer grid: autoplace */

.navigation {
    display: flex;
    box-shadow: 0px 3px 6px 0px #444;
    perspective: 0px;
}
.navigation > a {
    min-width: 33.33333%;
    width: 100%;
    padding: 1rem;
    font-size: .9rem;
    text-align: center;
    color: #FFF;
    background-color: #1e3464;
    -webkit-transition: background-color .6s ease-in-out, color .6s ease-in-out;
    transition: background-color .6s ease-in-out, color .6s ease-in-out;
}
.navigation > a.active {
    color: #000;
    background-color: #74a4c5;
    pointer-events: none;
}
.navigation > a:last-child {border-right: none}