/* autoprefixer grid: autoplace */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: #ffffff;
}

body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#FMCG-App {
    width: 100vw;
    height: 100vh;
    background-color: #1e3464;
    overflow: hidden;
}

[data-location="/"].fadeIn {
  animation: 500ms fadeIn forwards;
}
[data-location="/"].fadeOut {
  animation: 500ms fadeOut forwards;
}
[data-location="/magazine"].fadeIn {
  animation: 500ms slideInToLeft forwards;
}
[data-location="/magazine"].fadeOut {
  animation: 500ms slideOutToLeft forwards;
}
[data-location*="/mercantizori"].fadeIn {
  animation: 500ms slideInToLeft forwards;
}
[data-location*="/mercantizori"].fadeOut {
  animation: 500ms slideOutToLeft forwards;
}
[data-location*="/sefi-raioane"].fadeIn {
  animation: 500ms slideInToLeft forwards;
}
[data-location*="/sefi-raioane"].fadeOut {
  animation: 500ms slideOutToLeft forwards;
}
[data-location*="/agenti"].fadeIn {
  animation: 500ms slideInToLeft forwards;
}
[data-location*="/agenti"].fadeOut {
  animation: 500ms slideOutToLeft forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform-origin: center;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform-origin: center;
    transform: scale(1);
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes slideInToLeft {
  from {
    opacity: 0;
    transform: translate(10vw, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes slideOutToLeft {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    opacity: 0;
    transform: translate(-10vw, 0px);
  }
}