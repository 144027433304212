/* autoprefixer grid: autoplace */

.index-page {
    display: grid;
    grid-template-rows: minmax(30vh, 100vh) min-content;
    grid-template-areas:
        "logo"
        "nav";
    width: 100%;
    height: 100vh;
}

.index-page > .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.index-page > .logo > img {
    display: block;
    border: none;
    outline: none;
    border-radius: 100%;
    box-shadow: 0 0 5rem 2rem #142240;
    /*animation-name: breath;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;*/
}

.index-page > .nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    row-gap: 1rem;
}
.index-page > .nav > a {
    padding: 1rem;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 1px 2px rgb(0 0 0 / 25%);
    color: #1e3464;
    background-color: #FFFFFF;
    box-shadow: 0 8px 6px -6px #142240;
}
.index-page > .nav > a:last-child {
    margin-bottom: 1rem;
}

@keyframes breath {
    0%, 25% {
        rotate: -90deg;
        scale: 1;
    }
    50% {
        scale: .5
    }
    75%, 100% {
        rotate: 360deg;
        scale: 1;
    }
}