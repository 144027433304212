/* autoprefixer grid: autoplace */

.cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.shop-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F0F2F5;
    color: #1e3464;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
}
.shop-card:first-child{
    margin-top: 1rem;
}
.shop-card:last-child{
    margin-bottom: 0;
}
.shop-card > .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: calc(100% - 48px);
}
.shop-card > .card-header > .card-title {
    font-size: 1rem;
    font-weight: 700;
}
.shop-card > a {
    display: flex;
    padding: 1.5rem 1rem;
    color: #1e3464;
}