/* autoprefixer grid: autoplace */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F0F2F5;
    box-shadow: 0 8px 6px -6px #142240;
}
.header > .go-back-btn,
.header > .options-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-decoration: none;
    color: #1e3464;
}
.header > .options-btn {
    opacity: 0;
    pointer-events: none;
}
.header > .title {
    font-size: 1.25rem;
    color: #1e3464;
    text-transform: capitalize;
}