/* autoprefixer grid: autoplace */

.staff-cards::-webkit-scrollbar {
    width: 0;
}
.staff-cards {
    height: calc(100vh - 100px);
    overflow-y: scroll;
}
.staff-card {
    display: grid;
    grid-template-columns: 90% 10%;
    grid-template-rows: auto;
    grid-template-areas:
        "card-header details-cta"
        "card-body details-cta";
    background-color: #F0F2F5;
    color: #050505;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
}
.staff-card > .card-header {
    display: block;
    grid-area: card-header;
    padding: 1rem 1rem 0 1rem;
}
.staff-card > .card-header > .card-title {
    position: relative;
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: 1rem;
    font-weight: 700;
}
.staff-card > .card-header > .card-title > svg {
    margin-top: -0.25rem;
    fill: #ff5200;
    width: 1.25rem;
    height: 1.25rem;
}
.staff-card > .card-header > .card-subtitle {
    font-size: .9rem;
    text-transform: uppercase;
    padding-bottom: 1rem;
    border-bottom: 1px solid #CED0D4;
}
.staff-card > .card-body {
    grid-area: card-body;
    padding: 0 1rem;
}
.staff-card > .card-body > .card-projects {
    padding: 1rem 0;
    border-bottom: 1px solid #CED0D4;
}
.staff-card > .card-body > .card-condici {
    padding: 1rem 0;
    border-bottom: 1px solid #CED0D4;
}
.staff-card > .card-body > .card-condici > div {
    display: flex;
    align-items: center;
    gap: .5rem;
}
.staff-card > .card-body > .card-condici > div:first-child {
    margin-bottom: .25rem;
}
.staff-card > .card-body > .card-contact {
    display: flex;
    margin-left: -1rem;
    margin-right: -1rem;
}
.staff-card > .card-body > .card-contact > a {
    display: flex;
    align-items: center;
    padding: 1rem;
}
.staff-card > .card-body > .card-contact > a > svg {
    width: 1.15rem;
    height: 1.15rem;
}
.staff-card > .card-body .card-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
}
.staff-card > .card-body .card-group > .partner {
    padding: 0.25rem 0.25rem 0.15rem 0.25rem;
    font-size: .75rem;
    background-color: #1B74E4;
    color: #FFFFFF;
}
.staff-card > .details-cta {
    grid-area: details-cta;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1B74E4;
}

.multibrand {color: #ff006a}
.dedicat {color: #8500ff}
.shift-1 {color: #1bff00}
.shift-2 {color: #ff5200}
.phone-wapp {fill: #25D366}
.email {fill: #FC642D}